import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchInRunningFixturesSucceeded,
  fetchInRunningFixturesFailed,
  fetchMatrixSucceeded,
  fetchMatrixFailed,
  fetchInRunningFixtures as fetchInRunningFixtures_1,
  fetchBrowseSucceeded,
  fetchBrowseFailed,
} from "../actions/gamecenter"
import { get, post } from "../lib/fetch"

function* watchFetchInRunningFixtures() {
  return yield takeLatest(Type.FETCH_IN_RUNNING_FIXTURES, fetchInRunningFixtures)
}

function* fetchInRunningFixtures() {
  try {
    const selectedAccountNames = yield select((state) => state.gamecenter.selectedAccountNames)
    let url = "/api/game-center/in-running-fixtures"
    
    if (selectedAccountNames && selectedAccountNames.length > 0) {
      const accountParams = selectedAccountNames.map(name => `accountNames=${encodeURIComponent(name)}`).join('&')
      url = `${url}?${accountParams}`
    }
    
    const response = yield call(get, url)
    yield put(fetchInRunningFixturesSucceeded(response))
  } catch (e) {
    yield put(fetchInRunningFixturesFailed(e))
  }
}

// ---

function* watchFetchMatrix() {
  return yield takeLatest(Type.FETCH_MATRIX, fetchMatrix)
}

function* fetchMatrix(action) {
  const { fixtureId } = action
  try {
    const response = yield call(get, `/api/game-center/matrix?fixtureId=${fixtureId}`)
    yield put(fetchMatrixSucceeded(fixtureId, response))
  } catch (e) {
    yield put(fetchMatrixFailed(fixtureId, e))
  }
}

// ---

function* backgroundRefresh() {
  while (true) {
    const refresh = yield select((state) => state.gamecenter.backgroundRefresh)
    if (!refresh) {
      break
    }
    yield put(fetchInRunningFixtures_1())
    yield delay(5000)
  }
}

function* watchStartBackgroundRefresh() {
  yield takeLatest(Type.START_BACKGROUND_REFRESH, backgroundRefresh)
}

// ---

function* watchPostRemoveFixture() {
  return yield takeEvery(Type.POST_REMOVE_FIXTURE, postRemoveFixture)
}

function* postRemoveFixture(action) {
  const { fixtureId } = action
  try {
    yield call(post, `/api/game-center/remove?fixtureId=${fixtureId}`)
  } catch (e) {
    console.error(e)
  }
}

// ---

function* watchFetchBrowse() {
  return yield takeLatest(Type.FETCH_BROWSE, fetchBrowse)
}

function* fetchBrowse(action) {
  try {
    const { from, to, sport } = action
    const onlyWithOrders = yield select((state) => state.gamecenter.browseOnlyWithOrders)
    // const selectedAccountNames = yield select((state) => state.gamecenter.selectedAccountNames)
    
    let url = `/api/game-center/browse?from=${from}&to=${to}&onlyWithOrders=${onlyWithOrders}`
    
    if (sport) {
      url += `&sport=${sport}`
    }
    
    // if (selectedAccountNames && selectedAccountNames.length > 0) {
    //   selectedAccountNames.forEach(name => {
    //     url += `&accountNames=${encodeURIComponent(name)}`
    //   })
    // }
    
    const response = yield call(get, url)
    yield put(fetchBrowseSucceeded(response))
  } catch (e) {
    yield put(fetchBrowseFailed(e))
  }
}

// ---

function* watchPostUpdateFixture() {
  return yield takeEvery(Type.POST_UPDATE_FIXTURE, postUpdateFixture)
}

function* postUpdateFixture(action) {
  const { fixtureIds } = action
  try {
    yield call(post, "/api/game-center/update-fixtures-from-orders", fixtureIds)
  } catch (e) {
    console.error(e)
  }
}

// // ---
//
// function* watchFetchMatrixSucceeded() {
//   return yield takeLatest(GameCenter.Type.FETCH_MATRIX_SUCCEEDED, fetchWidgetData)
// }
//
// function* watchFetchWidgetData() {
//   return yield takeLatest(GameCenter.Type.FETCH_WIDGET_DATA, fetchWidgetData)
// }
//
// function* fetchWidgetData(action) {
//   try {
//     const {fixtureId} = action
//     const response = yield call(get, `/api/game-center/widget-data?fixtureId=${fixtureId}`)
//     yield put(GameCenter.fetchWidgetDataSucceeded(fixtureId, response))
//   } catch (e) {
//     console.error(e)
//     yield put(GameCenter.fetchWidgetDataFailed(e))
//   }
// }
//

// ---

export default function* gameCenterSaga() {
  yield all([
    watchFetchInRunningFixtures(),
    watchFetchMatrix(),
    watchStartBackgroundRefresh(),
    watchPostRemoveFixture(),
    watchPostUpdateFixture(),
    watchFetchBrowse(),
    // watchFetchWidgetData(),
    // watchFetchMatrixSucceeded()
  ])
}