import { combineReducers } from "redux"
import { Type } from "../actions/gamecenter"
import dayjs from "dayjs"

function inRunningFixturesLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_IN_RUNNING_FIXTURES:
      return true
    case Type.FETCH_IN_RUNNING_FIXTURES_SUCCEEDED:
    case Type.FETCH_IN_RUNNING_FIXTURES_FAILED:
      return false
    default:
      return state
  }
}

function inRunningFixtures(state = { list: [], ccy: "EUR" }, action) {
  switch (action.type) {
    case Type.FETCH_IN_RUNNING_FIXTURES_SUCCEEDED:
      return action.response
    default:
      return state
  }
}

function matrixLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_MATRIX:
      return true
    case Type.FETCH_MATRIX_SUCCEEDED:
    case Type.FETCH_MATRIX_FAILED:
      return false
    default:
      return state
  }
}

function matrix(state = { matrices: [], ccy: "EUR" }, action) {
  switch (action.type) {
    case Type.FETCH_MATRIX:
      return {
        ...state,
        fixtureId: action.fixtureId,
      }
    case Type.FETCH_MATRIX_SUCCEEDED:
      return {
        ...action.response,
        fixtureId: action.fixtureId,
      }
    case Type.HIDE_MATRIX:
      return { matrices: [], ccy: "EUR" }
    default:
      return state
  }
}

function showMatrix(state = false, action) {
  switch (action.type) {
    case Type.SHOW_MATRIX:
      return true
    case Type.HIDE_MATRIX:
      return false
    default:
      return state
  }
}

function matrixFromBrowse(state = false, action) {
  switch (action.type) {
    case Type.SHOW_MATRIX:
      return action.fromBrowse
    case Type.HIDE_MATRIX:
      return false
    default:
      return state
  }
}

function backgroundRefresh(state = false, action) {
  switch (action.type) {
    case Type.START_BACKGROUND_REFRESH:
      return true
    case Type.STOP_BACKGROUND_REFRESH:
      return false
    default:
      return state
  }
}

function browseLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_BROWSE:
      return true
    case Type.FETCH_BROWSE_SUCCEEDED:
    case Type.FETCH_BROWSE_FAILED:
      return false
    default:
      return state
  }
}
const defaultBrowse = { list: [], ccy: "EUR" }
function browse(state = defaultBrowse, action) {
  switch (action.type) {
    case Type.FETCH_BROWSE_SUCCEEDED:
      return action.response
    case Type.FETCH_BROWSE_FAILED:
      return defaultBrowse
    default:
      return state
  }
}

let defaultWidgetData = {
  home: {},
  away: {},
  trends: [],
  events: [],
  ccy: "EUR",
}

function widgetData(state = defaultWidgetData, action) {
  switch (action.type) {
    case Type.FETCH_WIDGET_DATA_SUCCEEDED:
      return {
        ...action.response,
        fixtureId: action.fixtureId,
      }
    default:
      return state
  }
}

function browseRange(
  state = {
    from: dayjs().startOf("day").valueOf(),
    to: dayjs().endOf("day").valueOf(),
  },
  action,
) {
  switch (action.type) {
    case Type.FETCH_BROWSE:
      return { from: action.from, to: action.to, sport: action.sport }
    default:
      return state
  }
}

function browseOnlyWithOrders(state = true, action) {
  switch (action.type) {
    case Type.TOGGLE_ONLY_WITH_ORDERS:
      return !state
    default:
      return state
  }
}

function selectedAccountNames(state = [], action) {
  switch (action.type) {
    case Type.SET_SELECTED_ACCOUNT_NAMES:
      return action.accountNames
    default:
      return state
  }
}

const reducer = combineReducers({
  inRunningFixtures,
  inRunningFixturesLoading,
  matrix,
  matrixLoading,
  showMatrix,
  matrixFromBrowse,
  backgroundRefresh,
  browseLoading,
  browse,
  widgetData,
  browseRange,
  browseOnlyWithOrders,
  selectedAccountNames,
})

export default reducer