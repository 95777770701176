import { useEffect } from "react"
import { startBackgroundRefresh, stopBackgroundRefresh } from "../../actions/balances"
import { fetchBrowse, fetchInRunningFixtures, setSelectedAccountNames } from "../../actions/gamecenter"
import { useDispatch, useSelector } from "react-redux"
import { AutoTrackingTrendingMoney, TrendingMoney } from "../TrendingMoney"
import Discretion from "../Discretion"
import "../../less/Balances.less"
import { Link, useLocation } from "react-router"
import { FilterFilled, FilterOutlined, LineChartOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"

const GameCenterBalances = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const balances = useSelector((state) => state.balances.balances)
  const inRunningFixtures = useSelector((state) => state.gamecenter.inRunningFixtures)
  const authorities = useSelector((state) => state.user.profile.authorities || [])
  const selectedAccountNames = useSelector((state) => state.gamecenter.selectedAccountNames || [])
  const browseRange = useSelector((state) => state.gamecenter.browseRange || {})

  const toggleAccountFilter = (accountName) => {
    let newSelectedAccounts = [...selectedAccountNames]

    if (selectedAccountNames.includes(accountName)) {
      newSelectedAccounts = newSelectedAccounts.filter((name) => name !== accountName)
    } else {
      newSelectedAccounts.push(accountName)
    }

    dispatch(setSelectedAccountNames(newSelectedAccounts))

    // Refresh data based on current route
    if (location.pathname === "/game-center") {
      dispatch(fetchInRunningFixtures())
    } else if (location.pathname === "/game-center/browse") {
      dispatch(fetchBrowse(browseRange.from, browseRange.to, browseRange.sport))
    }
  }

  useEffect(() => {
    dispatch(startBackgroundRefresh())

    return () => {
      dispatch(stopBackgroundRefresh())
    }
  }, [dispatch])

  const projectedAccountProfitLoss = inRunningFixtures ? inRunningFixtures.projectedAccountProfitLoss : {}
  const expectedAccountValue = inRunningFixtures ? inRunningFixtures.expectedAccountValue : {}

  return (
    <div className="balances">
      {Object.entries(balances || {}).map(([account, balance]) => {
        const projected = projectedAccountProfitLoss ? projectedAccountProfitLoss[account] || 0 : 0
        const expected = expectedAccountValue ? expectedAccountValue[account] || 0 : 0

        return (
          <div className="accountBalance" key={account}>
            <div className="account">
              {balance.hasDetailedBalances ? (
                <Link to={`/balances/${account}`}>
                  <Discretion>{account}</Discretion>
                </Link>
              ) : (
                <Discretion>{account}</Discretion>
              )}{" "}
              {authorities.includes("ROLE_TRADING_PERFORMANCE") && (
                <Link to={`/trading-performance/${account}`}>
                  <LineChartOutlined />
                </Link>
              )}{" "}
              <Tooltip
                title={
                  selectedAccountNames.includes(account)
                    ? "Exclude account from game center"
                    : "Include account in game center"
                }
              >
                <span onClick={() => toggleAccountFilter(account)} style={{ cursor: "pointer" }}>
                  {selectedAccountNames.includes(account) ? <FilterFilled /> : <FilterOutlined />}
                </span>
              </Tooltip>
            </div>

            <TrendingMoney value={balance.balance} ccy={balance.ccy} trend={balance.balanceTrend} className="balance" />

            {/*<TrendingMoney
              value={balance.credit}
              ccy={balance.ccy}
              trend={balance.balanceTrend}
              className='credit'
            >credit </TrendingMoney>*/}

            <TrendingMoney
              value={Math.abs(balance.openStake)}
              ccy={balance.ccy}
              trend={balance.openStakeTrend}
              className="openStake"
            >
              open{" "}
            </TrendingMoney>
            {projected !== 0 && (
              <>
                <AutoTrackingTrendingMoney
                  value={projected}
                  ccy={balance.ccy}
                  className="projectedDelta"
                  animationDuration={5000}
                  tolerance={50}
                  showSign
                  colored
                >
                  proj{" "}
                </AutoTrackingTrendingMoney>
              </>
            )}
            {expected !== 0 && (
              <>
                <AutoTrackingTrendingMoney
                  value={expected}
                  ccy={balance.ccy}
                  className="projectedDelta"
                  animationDuration={5000}
                  tolerance={50}
                  showSign
                  colored
                >
                  ev{" "}
                </AutoTrackingTrendingMoney>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default GameCenterBalances
